define("discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/url", ["exports", "@glimmer/component", "discourse/helpers/d-icon", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoursePostEventUrl extends _component.default {
    get url() {
      return this.args.url.includes("://") || this.args.url.includes("mailto:") ? this.args.url : `https://${this.args.url}`;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @url}}
          <section class="event__section event-url">
            {{icon "link"}}
            <a
              class="url"
              href={{this.url}}
              target="_blank"
              rel="noopener noreferrer"
            >
              {{@url}}
            </a>
          </section>
        {{/if}}
      
    */
    {
      "id": "Ri2iSeI/",
      "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,\"section\"],[14,0,\"event__section event-url\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"link\"],null]],[1,\"\\n        \"],[10,3],[14,0,\"url\"],[15,6,[30,0,[\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@url\"],false,[\"if\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/url.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DiscoursePostEventUrl;
});